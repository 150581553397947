export const list = [
  /* {
     title: 'Stake your $TKN',
     subtitle:'Reward $BUSD.',
     icon:{
       stakeCoin:'assets/images/icon/icon_bnb.png',
       rewardCoin:'assets/images/icon/icon_bnb.png'
     },
     apy:{
       label:'APY',
       value:'15%',
     },
     totalStake:{
       label:'Total Stake',
       value:'100.000 BNB'
     },
     totalStaker:{
       label:'Total Staker',
       value:'410'
     },
     contract:'0xAFFB7FeaA30fE78ECc2296452f4f1462e81b9bd8',
     link: '/staking/0xAFFB7FeaA30fE78ECc2296452f4f1462e81b9bd8',
     status:'participate'
   },
   {
       title: 'Stake your $USDT',
       subtitle:'Reward $BUSD.',
       icon:{
           stakeCoin:'assets/images/icon/icon_usdt.png',
           rewardCoin:'assets/images/icon/icon_bnb.png'
       },
       apy:{
         label:'APY',
         value:'14%',
       },
       totalStake:{
         label:'Total Stake',
         value:'100.000 USDT'
       },
       totalStaker:{
         label:'Total Staker',
         value:'230'
       },
       contract:'-',
       link: '/soon',
       status:'upcoming'
     },
     {
       title: 'Stake your $SHIB',
       subtitle:'Reward $BUSD.',
       icon:{
           stakeCoin:'assets/images/icon/icon_shib.png',
           rewardCoin:'assets/images/icon/icon_bnb.png'
       },
       apy:{
         label:'APY',
         value:'10%',
       },
       totalStake:{
         label:'Total Stake',
         value:'100.000 SHIB'
       },
       totalStaker:{
           label:'Total Staker',
           value:'320'
       },
       contract:'-',
       link: '/soon',
       status:'upcoming'
     },*/
  {
    title: 'Stake your $BTCN',
    subtitle: 'Reward $BTCN.',
    icon: {
      stakeCoin: 'assets/images/logo/token-icon.png',
      rewardCoin: 'assets/images/icon/icon_bnb.png'
    },
    apy: {
      label: 'APY',
      value: '15%',
    },
    totalStake: {
      label: 'Total Stake',
      value: '100.000 BNB'
    },
    totalStaker: {
      label: 'Total Staker',
      value: '410'
    },
    contract: '0x3A01D85857e5A3D9545299cF62907Ff3892825dD',
    link: '/staking/0x3A01D85857e5A3D9545299cF62907Ff3892825dD',
    status: 'participate'
  }
];
