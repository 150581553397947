export const periodeList = [
  {
    label: '3 Months',
    duration: '30d',
    period: '0'
  },
  {
    label: '6 Months',
    duration: '180d',
    period: '1'
  },
  {
    label: '9 Months',
    duration: '90d',
    period: '2'
  },
  {
    label: '12 Months',
    duration: '360d',
    period: '3'
  },
];
