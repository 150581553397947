import React, { useState } from 'react'
import StakePools from '../components/List/StakePools'
import { list } from '../data/StakingPoolLists';

function Home() {
    const [poolList, setPoolList] = useState(list);
    return (
        <>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">

                    <div className="col-lg-6">
                        <div className='head-bar' />
                        <h1 className="display-5 lh-1 my-3 title-text fw-600">Stake and Earn BTCN</h1>
                        <p className="lead py-3">Btcen offers you the opportunity to earn according to certain periods. You can have a fixed win with the stake rate you choose. Choose the one that works for you from 4 different stake periods and start earning immediately.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src="assets/images/misc/hero.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                    </div>
                </div>
            </div>
            <div className='container'>
                <StakePools poolList={poolList} />
            </div>
        </>
    )
}

export default Home
