
import AbiToken from "../abi/AbiToken.json";
import AbiStakingPool from "../abi/AbiStakingPool.json";
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};

//TESTNET
export const RPC_NODE_TESTNET = {
    http: "https://apis.ankr.com/7cf2e6641a924dc698832d73d071f077/a039c2b8470444d4b29a4ce141f6461e/binance/full/test",
    wss: "wss://apis.ankr.com/wss/7cf2e6641a924dc698832d73d071f077/a039c2b8470444d4b29a4ce141f6461e/binance/full/test"
}

export const RPC_NODE_MAIN = {
    http: "https://StakePool:Btcen123.@apis.ankr.com/e5c5baf441b2432cae2f042508e9ca05/a039c2b8470444d4b29a4ce141f6461e/binance/full/main'",
    wss: "wss://StakePool:Btcen123.@apis.ankr.com/wss/e5c5baf441b2432cae2f042508e9ca05/a039c2b8470444d4b29a4ce141f6461e/binance/full/main",

}

export const TOKEN_STAKE = {
    address: '0x5ad1Ba064aC6541a71eB8000eCa01be52F6ff526',
    abi: AbiToken
}

export const POOL_STAKE = {
    address: '0x3A01D85857e5A3D9545299cF62907Ff3892825dD',
    abi: AbiStakingPool
}